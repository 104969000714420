<template>
    <div>
        Providers
    </div>
</template>

<script>
export default {
    name:"ProvidersIndex",

    props: ['payload']
}
</script>
    